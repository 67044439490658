<template>
  <div class="background">
    <div class="position">
      <div>当前位置：  </div>
      <div>项目中心 &nbsp;&nbsp;>  </div>
      <div>项目列表 &nbsp;&nbsp;>    </div>
      <div>{{this.Info.projectShowInfoPage.projectName}}</div>
    </div>
    <div class="top">
      <div class="left">
        <div class="left_top">
          <p>{{ this.Info.projectShowInfoPage.projectName }}</p>
        </div>
        <div class="leftBottom">
          <p>需求领域：{{ this.Info.projectShowInfoPage.typeSecond }}</p>
          <p>开始时间：{{ this.Info.projectShowInfoPage.publishTime2 }}</p>
          <p>项目预算：￥{{ this.Info.projectShowInfoPage.budget }}</p>
        </div>
      </div>
      <div class="right">
        <div class="itemButton">
          <button>正在设计</button>
          <button>正在服务</button>
          <button>正在保障</button>
        </div>

        <div class="right_line">
          <p >当前进度：</p>
          <div class="circle">
          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">
          </div>
        </div>

        <div class="line_content">
          <p>客户发布</p>
          <p>任务报名</p>
          <p>客户选标</p>
          <p>中标确定</p>
          <p>签订协议</p>
          <p>服务阶段</p>
          <p>平台质检</p>
          <p>验收付款</p>
          <p>评价结束</p>
        </div>
        <div class="remind">
          <p>
            温馨提醒：报名本任务前需要完成身份认证
          </p>
          <div class="eye">
            <i style="font-size:.18rem;color: #a4a4a4" class="fa">&#xf06e</i>
            <span>72</span>
          </div>
          <div class="group">
            <i style="font-size:.18rem" class="fa">&#xf2c0;</i>
            <span>5</span>
          </div>
        </div>
      </div>
      <div class="label" @click="collectProject" style="cursor: pointer">
        <img src="../../images/SupplyChain-0.png" alt="">
      </div>
    </div>
    <div class="bottom">
      <div class="list">
        <div class="list_1">
          <p @click="this.introduceType = 1">任务介绍</p>
          <span></span>
        </div>
        <div class="list_2">
          <p @click="this.introduceType = 2">服务规范</p>
          <span></span>
        </div>
        <div class="list_3">
          <p @click="this.introduceType = 3">结果公布</p>
          <span></span>
        </div>
        <div class="list_4">
          <p @click="this.introduceType = 4">项目评论</p>
          <span></span>
        </div>
        <div class="tri" v-show="false"
        >
          <i style="font-size:.3rem;color: #ffffff" class="fa" v-show="introduceType === 1">&#xf0da</i>
          <i style="font-size:.3rem;color: #ffffff" class="fa" v-show="introduceType === 2">&#xf0da</i>
          <i style="font-size:.3rem;color: #ffffff" class="fa" v-show="introduceType === 3">&#xf0da</i>
          <i style="font-size:.3rem;color: #ffffff" class="fa" v-show="introduceType === 4">&#xf0da</i>
        </div>

        <div class="geo_1"></div>
        <div class="geo_2"></div>
        <div class="geo_3"></div>
      </div>
      <!--任务介绍开始-->
      <div class="right" v-show="this.introduceType === 1">
        <div class="taskIntroduce" >
          <div class="left_box">
            <div class="item_1">
              <div class="medium_head">
                <i></i>
                <p>项目背景</p>
              </div>
              <div class="task_info">
                {{ Info.projectMessage.background }}
              </div>
            </div>
            <div class="item_1">
              <div class="medium_head">
                <i></i>
                <p>风格要求</p>
              </div>
              <div class="task_info" title="1-设计风格：经典、轻奢、符号化 2-质感、玻璃瓶、考虑画面、磨砂 3-具备一定的创新性">
                1-设计风格：经典、轻奢、符号化 2-质感、玻璃瓶、考虑画面、磨砂 3-具备一定的创新性                </div>
            </div>
            <div class="item_1">
              <div class="medium_head">
                <i></i>
                <p>目标用户人群</p>
              </div>
              <div class="task_info">
                00-95后，也饮酒习惯的年轻都市女性，消费中高水平
              </div>
            </div>
            <div class="item_1">
              <div class="medium_head">
                <i></i>
                <p>相关资料</p>
              </div>
              <div class="task_info">

              </div>
            </div>
          </div>
          <div class="right_box">
            <div class="standard">
              <div class="medium_head">
                <i></i>
                <p>交付标准</p>
              </div>
              <div class="standard_box1">
                <p>设计交付物</p>
                <div class="submit">
                  <i style="font-size:.16rem" class="fa">&#xf115</i>
                  <i>初稿方案</i>
                </div>
                <div class="submit">
                  <i style="font-size:.16rem" class="fa">&#xf115</i>
                  <i>终稿方案</i>
                </div>
              </div>
              <div class="standard_box1">
                <p>中标交付物</p>
                <div class="submit" style="padding-left: .23rem">
                  <i>提案文件</i>
                </div>
                <div class="submit">
                  <i>设计效果稿</i>
                </div>
                <div class="submit">
                  <i>设计源文件</i>
                </div>

              </div>
              <p style="margin-left: .15rem;margin-top: -0.12rem;font-size: .14rem">(最终交付物内容以合同内为准)</p>
            </div>
            <div class="schedule">
              <div class="medium_head">
                <i></i>
                <p>时间安排</p>
              </div>
              <div>2020-12-27</div>
              <div>30天</div>
              <div>(最终时间以合同为准)</div>
              <el-button type="danger" @click="apply">我要报名</el-button>
            </div>
          </div>
        </div>
      </div>
      <!--任务介绍结束-->
      <!--服务规范-->
      <div class="serviceStandard" v-show="this.introduceType === 2">
        <div class="process">
          <div class="process_head">
            <i></i>
            <p>服务流程</p>
          </div>
          <div class="process_box">

          </div>
        </div>

        <div class="guarantee">
          <div class="guarantee_head">
            <i></i>
            <p>服务保障</p>
          </div>
          <div class="guarantee_box">
            <div class="left_box">
              <div class="left_box_title" >
                <p>线上流程</p>
                <p>便捷合同</p>
              </div>
              <div class="left_box_content">
                以多种灵活的合作协议保障服务的各个部分有序进行，采取先签署后动工的签约
                方式与保护各方的利益，签署方式多种多样，用电子盖章代替纸质合同减少邮寄
                合同带来的时间浪费和金钱浪费。
              </div>
            </div>
            <div class="right_box">
              <div class="right_box_title" >
                <p>确保合作质量</p>
                <p>提供完善流程</p>
              </div>
              <div class="right_box_content">
                <p>1.《项目需求书》</p>
                <p>2.《云平台服务委托协议》</p>
                <p>3.《外观设计确认书》</p>
                <p>4.《结构设计确认书》</p>
                <p>5.《项目结项报告》</p>
                <p>6.《客户满意度调查》</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--结果公布-->
      <div class="result" v-show="this.introduceType === 3">
        <div class="result_head">
          <i></i>
          <p>中标结果</p>
        </div>
        <div class="cards">
          <div v-for="(item,i) in this.Info.winBidUserShow">
            <div class="result_card" style="float: left">
              <div class="card_inf">
                <div class="touxiang">

                </div>
                <div class="name">
                  <div>设计师123</div>
<!--                  <div>{{ item.username }}</div>-->
                  <div class="renzheng"></div>
                </div>
                <div class="area">
                  <div class="shanchang">
                    <div>擅长领域：</div>
                    <div>{{ item.areaGood }}</div>
                    <div class="shugang">
                    </div>
                  </div>
                  <div class="ruxuan">
                    <div>入选任务：</div>
                    <div>{{ item.selectedNum }}</div>
                    <div class="shugang">
                    </div>
                  </div>
                  <div class="didian">
                    <div>{{ item.address }}</div>
                  </div>
                </div>
              </div>
              <button :style="item.stageStyle">{{ item.status }}</button>
            </div>
          </div>
        </div>
      </div>
      <!--项目评论-->
      <div class="comment" v-show="this.introduceType === 4">
        <div class="comment_left">
          <div class="comment_left_head">
            <i></i>
            <p>项目留言</p>
          </div>
          <div class="input_area">
            请输入您的留言内容......
          </div>
          <div class="message_request">
            <div class="links">
              <input type="checkbox">
              <i>《朗云云平台留言要求》</i>
            </div>
            <button>登陆发布</button>
          </div>


        </div>
        <div class="comment_right">
          <div class="comment_right_head">
            <i></i>
            <p>项目留言</p>
          </div>
          <div class="comment_area">
            <div class="img"></div>
            <div>暂时没有任何评论</div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Introduce",

  data(){
    return{
      ID:{
        pid:'',
      },
      Info:{
        projectMessage:{
          annex: '',
          background: '',
          beginTime: '',
          budget: '',
          companyName: '',
          content: '',
          deliverableType: '',
          endTime: '',
          id: '',
          linkman: '',
          needNum: '',
          otherInformation: '',
          period: '',
          phone: '',
          pic: '',
          projectName: '',
          publishTime: '',
          stage: '',
          status: '',
          style: '',
          targetPeople: '',
          transactionMode: '',
          typeFirst: '',
          typeSecond: '',
          uid: ''
        },
        projectShowInfoPage:{
          id:'',
          projectName:'',
          budget:'',
          typeSecond:'',
          publishTime:'',
          publishTime2:'',
        },
        projectSchedule:{
          acceptPayment: '',
          confirmWinbid: '',
          customerPublish: '',
          customerSelectbid: '',
          evaluate: '',
          id: '',
          pid: '',
          platformQuality: '',
          service: '',
          signAgreement: '',
          taskRegistration: '',
        },
        projectComments:{

        },
        projectTask:{
          deliver: '',
          designpicType: '',
          electronicFunction: '',
          id: '',
          material: '',
          period: '',
          pid: '',
          priorArea: '',
          schemeDeep: '',
          schemeFirst: '',
          sizeLimit: '',
          submitType: '',
        },
        winBidUserShow:[{
          address: '',
          areaGood: '',
          headPic: '',
          id: '',
          selectedNum: '',
          status: '',
          username: '',
          stageStyle:'',
        }],
      },
      introduceType: 1,
    }
  },
  mounted(){
    this.getInfo();
    //todo:完善该页面
  },
  methods:{
    getInfo(){
      this.ID.pid = parseInt(this.$route.query.id);
      this.$http({
        method: 'post',
        url: '/showProjectMessage',
        data: this.ID
      }).then(res => {
        this.Info = res.data;
        for(let i=0;i<this.Info.winBidUserShow.length;i++){
          if (this.Info.winBidUserShow[i].status === 1){
            this.Info.winBidUserShow[i].status = "已中标"
          }else if(this.Info.winBidUserShow[i].status === 2){
            this.Info.winBidUserShow[i].status = "已入选"
          }else if(this.Info.winBidUserShow[i].status === 0){
            this.Info.winBidUserShow[i].status = "未入选"
            this.Info.winBidUserShow[i].stageStyle = "background-color: #9e9e9e;"
          }
        }
      })
    },
    collectProject(){
      this.$http({
        method: 'post',
        url: '/collectProject',
        data: this.ID
      }).then(res => {
        if (res.data.code === 401) {
          this.$notify({
            title: '收藏失败',
            message: '请登录',
            type: 'warning'
          });
        }else {
          this.$notify({
            title: '收藏成功',
            message: '收藏成功',
            type: 'success'
          });
        }
      });
    },
    apply(){
      this.$router.push({path:'/ServiceDetail',query: {projectId: this.ID.pid}})
    }
  }
}
</script>

<style scoped>
.background{
  width: 19.2rem;
  height: 11rem;
  background-color: #fdf9f8;
  padding-top: .52rem;
}

.position {
  /*margin-top: .52rem;*/
  height: .18rem;
  margin-bottom: .3rem;
  margin-left: 1.8rem;
}

.position div{
  width: 1rem;
  float: left;
  font-size: .18rem;
  color: #5e5e5e;
  margin-right: .06rem;
  /*margin-right: .05rem;*/
}

.position div:nth-child(1){
  color: #212121;
}

.position div:nth-child(4){
  width: 1.5rem;
  color: #ec414d;
}

.top{
  position: relative;
  width: 15.6rem;
  height: 3.3rem;
  border-radius: .05rem;
  /*background-color: pink;*/
  padding-top: .5rem;
  margin: 0 auto .5rem;
  background-color: #ffffff;
  box-shadow: 0 0 .05rem rgba(0,0,0,.2);

}

.top .label{
  position: absolute;
  width: .77rem;
  height: .5rem;
  top: .37rem;
  right: 0rem;
}

.top .label img{
  width: 100%;
  height: 100%;
}

.left{
  float: left;
  width: 2.98rem;
  height: 2.44rem;
  margin-left: .6rem;
  border-right: .01rem solid #a4a4a4;

}

.top .left_top p:nth-child(1){
  height: .24rem;
  color: #212121;
  font-size: .24rem;
  margin-bottom: .2rem;
}

.top .left_top p:nth-child(2){
  height: .36rem;
  color: #212121;
  font-size: .36rem;
  margin-bottom: .65rem;
}

.top .leftBottom{
  height: .86rem;
  font-size: .18rem;
  color: #a4a4a4;

}

.top .leftBottom p{
  height: .18rem;
  margin-bottom: .19rem;
}

.right{
  float: left;
  height: 2.44rem;
  margin-left: .5rem;

}

.right .itemButton{
  margin-bottom: .35rem;
}

.right .itemButton button{
  width: .9rem;
  height: .3rem;
  font-size: .14rem;
  background-color: #ff655f;
  margin-right: .1rem;
  color: #ffffff;
  border-radius: .05rem;
}

.right .right_line{
  height: 1.1rem;
}

.right .right_line p{
  font-size: .18rem;
  color: #212121;
  margin-bottom: .4rem;
}

.right .right_line i{
  text-align: center;
  width: .97rem;
}

.right .right_line div{
  float:left;
  width: .2rem;
  height: .2rem;
  border-radius: 50%;
  background-color: #ed404a;
}

.right .right_line div:nth-of-type(n+5){
  background-color: grey;
}


.right .right_line i{
  float: left;
  height: .2rem;
  color: #ed404a;
  letter-spacing: .02rem;
  font-size: .15rem;
  margin-top: -0.02rem;
}

.right .right_line .circle:nth-child(2){
  margin-left: .3rem;
}

.right .line_content p{
  float: left;
  color: #212121;
  font-size: .18rem;
  margin-right: .45rem;
}

.right .line_content{
  height: .18rem;
  margin-bottom: .3rem;
}

.right .remind {
  position: relative;
  height: .14rem;
}

.right .remind p{
  float: left;
  font-size: .14rem;
  color: #adadad;
}

.right .remind i{
  float: left;
  margin-right: .05rem;
}

.right .remind .eye{
  float: right;
  width: .45rem;
  height: .18rem;
  position: absolute;
  right: 1.2rem;
}

.right .remind .eye span{
  height: .18rem;
  float: left;
  font-size: .14rem;
  line-height: .18rem;
}

.right .remind .group{
  float: right;
  width: .45rem;
  height: .18rem;
  position: absolute;
  right: .6rem;
}

.right .remind .group span{
  height: .18rem;
  float: left;
  font-size: .14rem;
  line-height: .18rem;
}

.bottom{
  position: relative;
  width: 15.6rem;
  height: 4.85rem;
  border-radius: .05rem;
  background-color: #ffffff;
  margin: 0 auto;
}

.bottom .list{
  position: relative;
  /*padding-top: .7rem;*/
}

.bottom .list div{
  width: .84rem;
  height: .37rem;
  margin-left: .8rem;
  margin-bottom: .4rem;
  color: #ffffff;
  font-weight: 400;
  font-size: .21rem;
  cursor: pointer;

}

.bottom .list p{
  width: 100%;
  height: .21rem;
  margin-bottom: .15rem;
  line-height: .21rem;
}
.bottom .list span{
  display: block;
  height: .02rem;
  width: 0rem;
  background-color: #ffffff;
  transition: all .7s;
}

.bottom .list div:hover span{
  width: 100%;
}


.bottom .right{
  width: 12.6rem;
  float: left;
}

.bottom .list{
  float: left;
  position: relative;
  padding-top: .7rem;
  width: 2.15rem;
  height: 100%;
  background-color: #ed404a;
}

.bottom .list i:nth-child(1){
  position: absolute;
  top: .7rem;
  left: .48rem;
}

.bottom .list i:nth-child(2){
  position: absolute;
  top: 1.48rem;
  left: .48rem;
  display: none;
}

.bottom .list i:nth-child(3){
  position: absolute;
  top: 2.25rem;
  left: .48rem;
  display: none;

}

.bottom .list i:nth-child(4){
  position: absolute;
  top: 2.96rem;
  left: .48rem;
  display: none;

}

.bottom .list .geo_1{
  position: absolute;
  /*float: left;*/
  top: .4rem;
  right: .22rem;
  width: .72rem;
  height: .74rem;
  background: url("../../images/geo_1_1.png") no-repeat;
  background-size: .72rem .74rem;
}

.bottom .list .geo_1{
  animation-name: run;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}


.bottom .list .geo_2{
  position: absolute;
  top: 2.2rem;
  right: 1.18rem;
  width: .72rem;
  height: .74rem;
  background: url("../../images/geo_1_2.png") no-repeat;
  background-size: .72rem .74rem;
}

.bottom .list .geo_2{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bottom .list .geo_3{
  position: absolute;
  bottom: .05rem;
  right: .22rem;
  width: .72rem;
  height: .74rem;
  background: url("../../images/geo_1_4.png") no-repeat;
  background-size: .72rem .74rem;
}

.bottom .list .geo_3{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.taskIntroduce{
  padding-top: .3rem;
}

.taskIntroduce .left_box{
  float: left;
  width: 6.38rem;
  height: 4.34rem;
  /*padding-top: .3rem;*/
  margin-right: .78rem;
  /*background-color: pink;*/
}

.taskIntroduce .left_box .item_1{
  width: 6.38rem;
  height: .78rem;
  margin-bottom: .4rem;
  /*border: .01rem solid #c2c2c2;*/
  /*margin-left: .48rem;*/

}

.taskIntroduce .left_box .task_info{
  width: 6.38rem;
  height: .54rem;
  line-height: .54rem;
  border: .01rem solid #c2c2c2;
  font-size: .16rem;
  padding-left: .07rem;
  white-space: nowrap;
  /*设置内容不换行*/
  text-overflow: ellipsis;
  /*设置文字超出文本框的内容显示成...*/
  overflow: hidden;
  /*超出部分隐藏*/
}

.taskIntroduce .right_box{
  float: left;
  width: 5.38rem;
  height: 3.42rem;
  color: #5c5c5c;
  /*background-color: purple;*/
}

.taskIntroduce .right_box .standard{
  width: 5.38rem;
  height:1.84rem;
  margin-bottom: .6rem;
}

.taskIntroduce .right_box .standard .standard_box1{
  width: 5.28rem;
  height: .56rem;
  margin-left: .15rem;
  margin-bottom: .3rem;
}

.taskIntroduce .right_box .standard .standard_box1 p{
  font-size: .16rem;
  color: #5c5c5c;
  margin-bottom: .12rem;
  font-weight: 500;

}

.taskIntroduce .right_box .standard .standard_box1 .submit{
  float: left;
  width: 1.02rem;
  height: .32rem;
  margin-right: .24rem;
  padding-left: .16rem;
  padding-top: .06rem;
  border: .01rem solid #212121;
  border-radius: .05rem;
}

.taskIntroduce .right_box .standard .standard_box1 .submit i{
  font-size: .14rem;

}

.taskIntroduce .right_box .schedule div{
  width: 2rem;
  height: .16rem;
  margin-bottom: .15rem;
  font-size: .16rem;
}

.taskIntroduce .right_box .schedule div:nth-of-type(2)::before{
  content: "预计启动时间：";
}

.taskIntroduce .right_box .schedule div:nth-of-type(2)::before{
  content: "预计周期：";
}

.medium_head{
  width: 2rem;
  height: .18rem;
  color: #212121;
  /*margin-left: .48rem;*/
  margin-bottom: .2rem;

}

.medium_head i{
  display: block;
  float: left;
  width: .03rem;
  height: .18rem;
  margin-right: .04rem;
  background-color: #ec414d;
}

.medium_head p{
  float: left;
  width: 1.08rem;
  height: .18rem;
  line-height: .18rem;
  /*margin-top: .1rem;*/
  font-size: .18rem;
  font-weight: 600;
  color: #5c5c5c;
}

.serviceStandard{
  position: absolute;
  /*top: 0;*/
  height: 4.85rem;
  width: 13.45rem;
  /*margin-left: -0.5rem;*/
  /*bottom: 0rem;*/
  left: 2.15rem;
  background-color:#ffffff;
  padding-top: .5rem;

}

.serviceStandard .process{
  float: left;
  width: 5.6rem;
  height: 100%;
}

.serviceStandard .process_head{
  width: 5.6rem;
  height: .18rem;
  margin-left: .7rem;
  margin-bottom: .25rem;
}

.serviceStandard .process_head i{
  float: left;
  height: .18rem;
  width: .05rem;
  margin-right: .1rem;
  border-radius: .15rem;
  background-color: #ec414d;
}

.serviceStandard .process_head p{
  float: left;
  height: .18rem;
  font-size: .18rem;
  color: #212121;
  line-height: .18rem;

}

.serviceStandard .process_box{
  width: 5.6rem;
  height: 3.38rem;
  background-color: #f8f8f8;
  margin-left: .7rem;
  background-image: url("../../images/流程.png");
  background-size: 100% 100%;
}

.serviceStandard .guarantee{
  float: left;
  width: 5.6rem;
  height: 100%;
  margin-left: .65rem;
}

.serviceStandard .guarantee_head{
  width: 5.6rem;
  height: .18rem;
  margin-left: .7rem;
  margin-bottom: .25rem;
}

.serviceStandard .guarantee_head i{
  float: left;
  height: .18rem;
  width: .05rem;
  margin-right: .1rem;
  border-radius: .15rem;
  background-color: #ec414d;
}

.serviceStandard .guarantee_head p{
  float: left;
  height: .18rem;
  font-size: .18rem;
  color: #212121;
  line-height: .18rem;

}

.serviceStandard .guarantee_box{
  width: 5.6rem;
  height: 3.38rem;
  background-color: #f8f8f8;
  margin-left: .7rem;
  /*margin-top: .5rem;*/
}

.serviceStandard .guarantee_box .left_box{
  float: left;
  width: 2.8rem;
  height: 2.68rem;
  border-right: .01rem solid #5e5e5e;
  margin-top: .49rem;
  padding-left: .44rem;
}

.serviceStandard .guarantee_box .left_box p{
  float: left;
  font-size: .16rem;
  font-weight: 700;
  margin-right: .05rem;
  color: #212121;
}

.serviceStandard .guarantee_box .left_box .left_box_content{
  width: 2.04rem;
  /*margin-left: .44rem;*/
  height: 2rem;
  margin-top: .4rem;
  /*background-color: #1ea1e0;*/
  font-size: .14rem;
  color: #999899;
}


.serviceStandard .guarantee_box .right_box {
  float: left;
  height: 2.68rem;
  /*width: 2.6rem;*/
  padding-top: .49rem;
  margin-left: .3rem;
  padding-left: .3rem;
}

.serviceStandard .guarantee_box .right_box_title{
  height: .16rem;
  margin-bottom: .2rem;
}

.serviceStandard .guarantee_box .right_box .right_box_title p{
  float: left;
  height: .16rem;
  line-height: .16rem;
  font-size: .16rem;
  font-weight: 700;
  /*margin-right: .05rem;*/
  margin-right: .05rem;
  /*margin-left: .3rem;*/
  color: #212121;
  margin-bottom: .2rem;

}

.serviceStandard .guarantee_box .right_box .right_box_content{
  font-size: .14rem;
  color: #999899;
}

.serviceStandard .guarantee_box .right_box .right_box_content p{
  height: .14rem;
  margin-bottom: .15rem;
}

/*以下是结果公示页面的CSS代码*/

.result{
  position: absolute;
  /*top: 0;*/
  height: 4.85rem;
  width: 13.45rem;
  /*margin-left: -0.5rem;*/
  /*bottom: 0rem;*/
  left: 2.15rem;
  background-color:#ffffff;
  padding-top: .5rem;
  padding-left: .7rem;

}

.result .result_head{
  height: .21rem;
  margin-bottom: .26rem;
}

.result .result_head i{
  float: left;
  height: .21rem;
  width: .05rem;
  margin-right: .1rem;
  border-radius: .15rem;
  background-color: #ec414d;
}

.result .result_head p{
  float: left;
  height: .21rem;
  font-size: .21rem;
  color: #212121;
  line-height: .21rem;
}

.result .cards{
  /*float: left;*/
  width: 100%;
  height: 3.38rem;
}

.result .cards .result_card{
  width: 5.55rem;
  height: 1.02rem;
  margin-bottom: .14rem;
  margin-right: .65rem;
  background-color: #f8f8f8;
  padding-top: .19rem;

}

.result .cards .result_card button{
  width: 1rem;
  height: .35rem;
  margin-top: .15rem;
  color: #fef6f6;
  background-color: #ec414d;
  /*border: .01rem solid transparent;*/
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;
}

/*.result .cards .result_card button:hover{*/
/*  background-size: 300%;*/
/*}*/

.result .cards .result_card .touxiang{
  float: left;
  width: .65rem;
  height: .65rem;
  margin-left: .5rem;
  margin-right: .2rem;
  border-radius: 50%;
  /*background-color: #1ea1e0;*/
  background-image: url("../../images/tx.png");
  background-size: 100%;
  /*margin-top: .19rem;*/
}

.result .cards .result_card .name{
  float: left;
  /*float: left;*/
  width: 2.42rem;
  height: .16rem;
  font-size: .16rem;
  margin-bottom: .2rem;
  color: #212121;
  margin-top: .08rem;
}

.result .cards .result_card .name div{
  float: left;
}

.result .cards .result_card .area{
  float: left;
  height: .12rem;
  width: 2.8rem;
  font-size: .12rem;
  color: #c5c5c5;
  line-height: .12rem;
  /*margin-top: .13rem;*/
}

.result .cards .result_card .area div{
  float: left;
}

.result .cards .result_card .area .ruxuan{
  margin-left: .06rem;
  margin-right: .06rem;
}

.result .cards .result_card .renzheng{
  float: left;
  width: .16rem;
  height: .22rem;
  background: url("../../images/认证.png");
  background-size: 100% 100%;
  margin-left: .1rem;
  margin-right: .4rem;
}

.comment{
  padding-top: .51rem;
}

.comment .comment_left{
  float: left;
  width: 5.86rem;
  height: 3.94rem;
  padding-left: .7rem;
  /*padding-top: .5rem;*/
  margin-bottom: .23rem;
  border-right: .01rem solid #f2e0e0;

}

.comment .comment_left .comment_left_head{
  height: .21rem;
  margin-bottom: .26rem;
}

.comment .comment_left .comment_left_head i{
  float: left;
  height: .21rem;
  width: .05rem;
  margin-right: .1rem;
  border-radius: .15rem;
  background-color: #ec414d;
}

.comment .comment_left .comment_left_head p{
  float: left;
  height: .21rem;
  font-size: .21rem;
  color: #212121;
  line-height: .21rem;
}

.comment .comment_left .input_area{
  width: 4.44rem;
  height: 2.85rem;
  background-color: #f8f8f8;
  margin-bottom: .2rem;
  font-size: .16rem;
  color: #a8a8a8;
  padding-left: .3rem;
  padding-top: .3rem;
}

.comment .comment_left .message_request{
  height: .36rem;
  /*line-height: .36rem;*/

}

.comment .comment_left .links{
  float: left;
  height:.2rem;
  font-size: .14rem;
  margin-right: 2.05rem;
  line-height: .2rem;
  color: #a8a8a8;
  margin-top: .11rem;
  cursor: pointer;
  /*margin: auto 0;*/
}

.comment .comment_left .links i:hover{
  color: #ec414d;
}

.comment .comment_left .links input[type=checkbox]{
  width: .16rem;
  height: .16rem;
  margin-top: .02rem;
  position: relative;
  cursor: pointer;
}
.comment .comment_left .links input[type=checkbox]::after {
  position: absolute;
  top: 0;
  color: #000;
  width: .16rem;
  height: .16rem;
  display: inline-block;
  visibility: visible;
  padding-left: 0rem;
  text-align: center;
  content: ' ';
  border-radius: .03rem;
}
.comment .comment_left .links input[type=checkbox]:checked::after {
  content: "✓";
  color: #fff;
  font-size: .1rem;
  line-height: .15rem;
  background-color: #ec414d;
}


.comment .comment_left button{
  width: 1rem;
  height: .36rem;
  background-color: #ec414d;
  /*border: .01rem solid transparent;*/
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;
  color: #ffffff;
  font-size: .13rem;
}

.comment .comment_left button:hover{
  background-size: 300%;
}

.shugang{
  float:left;
  width: .02rem;
  height: .12rem;
  /*line-height: .12rem;*/
  background: #c5c5c5;
  margin-left: .06rem;
  /*margin-left: 5rem;*/
}

.comment .comment_right{
  float: left;
  /*width: 100%;*/
  height: 3.94rem;
  margin-left: .7rem;
}


.comment .comment_right .comment_right_head{
  height: .21rem;
  margin-bottom: .26rem;
}

.comment .comment_right .comment_right_head i{
  float: left;
  height: .21rem;
  width: .05rem;
  margin-right: .1rem;
  border-radius: .15rem;
  background-color: #ec414d;
}

.comment .comment_right .comment_right_head p{
  float: left;
  height: .21rem;
  font-size: .21rem;
  color: #212121;
  line-height: .21rem;
}

.comment .comment_right{
  float: left;
  width: 6.75rem;
  height: 1.46rem;
  font-size: .21rem;
  color: #b4b4b4;
  text-align: center;

}

.comment .comment_right .img{
  width: .93rem;
  height: .89rem;
  background:url("../../images/empty.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: .3rem;
  margin-top: 1rem;

}

@keyframes  run{
  0%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,.8rem);
  }
}

@keyframes rotate {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}









</style>